import React from 'react'
import { ShopName } from '../../constants'
import Facebook from './Facebook'
import Twitter from './Twitter'

import Head from 'next/head'
import { useRouter } from 'next/router'

// https://www.gatsbyjs.org/docs/add-seo-component/
// examples:
// https://github.com/marisamorby/marisamorby.com/tree/master/packages/gatsby-theme-blog-sanity
// https://github.com/LeKoArts/gatsby-starter-prismic/blob/master/src/components/SEO/SEO.jsx

type Props = {
  title?: string
  description?: string
  image?: string
  type?: 'article' | 'product' | 'website'
  author?: string
  datePublished?: string
  product?: {
    name: string
    description: string
    sku: string
    mpn?: string
    image: string[]
    review: {
      ratingValue: string
      bestRating: string
    }
    reviewAuthor: {
      name: string
    }
    aggregateRating: {
      ratingValue: string
      reviewCount: string
    }
    offers: {
      /**
       * Direct URL to the product
       */
      url: string
      /**
       * uppercase `USD`
       */
      priceCurrency: string
      price: string
      priceValidUntil?: string
      seller: {
        /**
         * Shop name
         */
        name: string
      }
    }
  }
}

const siteMetadata = {
  title: `${ShopName} - Premium CBD Oil Drops, Capsules & Creams`,
  description: `${ShopName} is the #1-rated premium hemp CBD oil. Our products are completely THC free, vegan, American grown and GMO free.`,
  author: `${ShopName}`,
  titleTemplate: `%s - ${ShopName}`,
  twitterUsername: '@penguincbd',
  url: 'https://penguincbd.com', // no trailing slash!
  siteUrl: 'https://penguincbd.com', // no trailing slash!
  image: '/images/seo-default.jpg',
  titleAlt: `${ShopName}`,
  siteLanguage: 'en',
  headline: `${ShopName} is the #1-rated premium hemp CBD oil. Our products are completely THC free, vegan, American grown and GMO free.`,

  ogLanguage: 'en_US', // Facebook Language
  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  banner: '/images/cover.jpg',
  shortName: 'Penguin CBD', // shortname for manifest. MUST be shorter than 12 characters
  themeColor: '#000000',
  backgroundColor: '#ffffff',
  facebook: 'penguin',
}

const SEO = (props: Props) => {
  const { asPath } = useRouter()
  const {
    siteUrl,
    twitterUsername,
    facebook,
    ogLanguage,
    headline,
    siteLanguage,
  } = siteMetadata

  const {
    title = siteMetadata.title,
    description = siteMetadata.description,
    image = siteMetadata.image,
    type = 'website',
    product,
    author,
    datePublished,
  } = props

  const article = type === 'article'
  const website = type === 'website'

  const seo = {
    title,
    description,
    // image: `${siteUrl}${image}`,
    url: `${siteUrl}${asPath}`,
    image,
  }

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: siteMetadata.description,
    name: siteMetadata.title,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2019',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished,
    // dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${siteMetadata.banner}`,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ]

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      author: {
        '@type': 'Person',
        name: author,
      },
      copyrightHolder: {
        '@type': 'Person',
        name: author,
      },
      copyrightYear: '2020',
      creator: {
        '@type': 'Person',
        name: author,
      },
      datePublished,
      dateModified: datePublished,
      publisher: {
        '@type': 'Organization',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${siteMetadata.banner}`,
        },
      },
      description: seo.description,
      headline: seo.title,
      inLanguage: siteLanguage,
      url: seo.url,
      name: seo.title,
      image: {
        '@type': 'ImageObject',
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 2,
    })
  }

  let productSchema

  if (!product && type === 'product') {
    console.warn(`[seo] missing product prop for type: "product". ${props}`)
  }
  if (type === 'product' && product) {
    const hasValidRating =
      Number(product.aggregateRating?.ratingValue) > 0 &&
      Number(product.aggregateRating?.reviewCount) > 0

    productSchema = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: product.name,
      image: product.image,
      description: product.description,
      sku: product.sku,
      mpn: product.sku,
      brand: {
        '@type': 'Brand',
        name: ShopName,
      },
      ...(hasValidRating && {
        review: {
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: product.review.ratingValue,
            bestRating: product.review.bestRating,
          },
          author: {
            '@type': 'Person',
            name: product.reviewAuthor?.name || 'Verified Buyer',
          },
        },
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: product.aggregateRating.ratingValue,
          reviewCount: product.aggregateRating.reviewCount,
        },
      }),
      offers: {
        '@type': 'Offer',
        url: product.offers.url,
        priceCurrency: 'USD',
        price: product.offers.price?.replace('$', ''),
        priceValidUntil: '2024-12-01',
        itemCondition: 'https://schema.org/NewCondition',
        availability: 'https://schema.org/InStock',
        seller: {
          '@type': 'Organization',
          name: product.offers.seller.name,
        },
      },
    }
  }

  // eslint-disable-next-line react/jsx-no-undef

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  return (
    <React.Fragment>
      <Head>
        <meta
          name="ahrefs-site-verification"
          content="cd0d846595266294f83ac409a32c54e26d70ca280cf222b0a29282507589609a"
        />
        <title>{seo.title}</title>
        <meta name="title" content={seo.title} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {seo.url && <meta property="og:url" content={seo.url} />}
        {article && <meta property="og:type" content="article" />}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta name="twitter:card" content="summary_large_image" />
        {twitterUsername && (
          <meta name="twitter:creator" content={twitterUsername} />
        )}
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!!article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        {!!website && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {type === 'product' && (
          <script type="application/ld+json">
            {JSON.stringify(productSchema)}
          </script>
        )}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Head>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username={twitterUsername}
      />
    </React.Fragment>
  )
}
export default SEO
